import axios from "../../Schemas/API";
import React, { useEffect, useState } from "react";
import Prev from "../../Images/previous.png";
import Next from "../../Images/next.png";
import { useNavigate } from "react-router";
import Eye from "../../Images/eye.png";
import Delete from "../../Images/delete.png";
import { MdEdit } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { DragDropContext, Droppable ,Draggable} from "react-beautiful-dnd";

const Academy = () => {
 const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [serchqurey, setSerchqurey] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [popu, setPopu] = useState([]);
  const MyToken = localStorage.getItem("TOKEN");
  // const getInvester = async (page) => {
  //   try {
  //     const res = await axios.post(
  //       "blog/get",
  //       {
  //         page: page.toString(),
  //         keyword: serchqurey,
  //         type:"1"
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${MyToken}`,
  //         },
  //       }
  //     );
  //     console.log(res,"Blog")
  //     setUser(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const getInvester = async () => { 
  try {
    const res = await axios.post(
      "blog/get",
      {
        keyword: serchqurey,
        type: "2",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MyToken}`,
        },
      }
    );
    setUser(res.data.data);
    setTotalPages(res.data.totalPages)
  } catch (error) {
  }
};

  useEffect(() => {
    getInvester(currentpage, "1");
  }, [currentpage,serchqurey ]);

 const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);
    setCurrentpage(nextPage);
  };
  const navigate = useNavigate();
  const handleEditInvester = (item) => {
    navigate("/EditAcademy", {
      state: {
        item,
      },
    });
    };
  
   const handleShow = (item) => {
    setPopu(item);
    setShow(true);
  };
  //  const PostDelete = async () => {
  //   try {
  //     const res = await axios.post(
  //       "blog/delete",
  //       {
  //         id: popu?.id.toString(),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${MyToken}`,
  //         },
  //       }
  //     );
  //     setShow(false);
  //     getInvester();
  //     console.log(res, "delete ho gya");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const PostDelete = async (id) => {
  if (!id) {
    return;
  }
  try {
    const res = await axios.post(
      "blog/delete",
      {
        id: id.toString(),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MyToken}`,
        },
      }
    );

    setShow(false);
    await getInvester();
  } catch (error) {
  }
};

   const Deletepopup = () => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Academy Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img src={popu?.image} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu?.title_english}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this Academy?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(popu?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;
    const reorderedList = Array.from(user);
    const [movedItem] = reorderedList.splice(source.index, 1);
    reorderedList.splice(destination.index, 0, movedItem);
    setUser(reorderedList);
    const reorderedData = {
        items: reorderedList.map(item => ({ id: item.id.toString() }))
    };
    try {
      const res = await axios.post("https://api.turkafrica.market/admin/v1/blog/order",reorderedData ,{
        headers:{
          "Content-Type":"application/json",
          Authorization: `Bearer ${MyToken}`,
        }
      })
      // toast.success(res.data.message)
      console.log(res,"reeeee")
    } catch (error) {
      // console.log(error,"Errrorrrrrrr")
    }
};
  
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />
      <div className="new-add-important-flex">
        {/* <Sidebar /> */}
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Academy
                </a>
              </div>
            </div>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two" style={{gap:"2rem"}}>
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label htmlFor="Search">Search:</label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="Search"
                   value={serchqurey}
                onChange={(e) => setSerchqurey(e.target.value)}
                  />
                </div>
                <button style={{width:"120px",height:"40px",backgroundColor:"red",color:"#fff",border:"none",borderRadius:"4px"}} onClick={() => navigate("/AddAcademy")}>Add Academy</button>
              </div>

              <div className="easy-add-scroll-new">
                <div className="card" style={{ marginTop: "2rem" }}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>                           
                            <th>Action</th>                           
                          </tr>
                        </thead>
                        {/* <tbody>
                          {user && user.length > 0 ? (
                            user.map((item, index) => {
                              const serialNumber = index + 1;
                              return (
                                <>
                                  <tr>
                                          <td>{serialNumber}</td>
                                           <td>
                        <img
                          src={item.image}
                          alt=""
                                                  className="img-popu-heres"
                                                  style={{width:"60px",height:"60px",borderRadius:"10px",}}
                        />
                      </td>
                                    <div
                                      style={{ display: "flex", gap: "0.5rem",border:"none" }}
                                    >

                                       <td className="responsive-design" style={{ border: "none" }}>
  {item.title_english.length > 20
    ? item.title_english.substring(0, 20) + "..."
    : item.title_english}
</td>
                                    </div>
                                   
                                    <td
                                      style={{border:"none"}}
                                    >
                                      
                                      <td className="responsive-design" style={{ border: "none" }}>
  {item.description_english
    .replace(/<\/?[^>]+(>|$)/g, '')
    .length > 50 
    ? `${item.description_english.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 50)}...` 
    : item.description_english.replace(/<\/?[^>]+(>|$)/g, '')}
</td>


                                    </td>
                                       <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            className="delete-buttons-red"
                            style={{ border: "1px solid goldenrod" }}
                            onClick={() =>
                                            handleEditInvester(item)
                                          }
                          >
                           
                                          <MdEdit
                                            
                                            className="delete-btn-red"
                                            style={{
                                              color: "goldenrod",
                                              marginTop: "0.5rem",
                                            }}
                                          />
                          </button>
                          <button
                            className="delete-buttons-red"
                            onClick={() => {
                              handleShow(item);
                            }}
                          >
                            <img
                              src={Delete}
                              alt="Delete"
                              className="delete-btn-red"
                            />
                          </button>
                        </div>
                      </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8">Data Not Found</td>
                            </tr>
                          )}
                        </tbody> */}



<DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="userList">
                        {(provided) => (
                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {user && user.length > 0 ? (
                         user.map((item, index) => (
                         <Draggable key={item.id || `user-${index}`} draggableId={String(item.id || index)} index={index}>
                         {(provided) => (
                          <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={item.image}
                      alt=""
                      className="img-popu-heres"
                      style={{ width: "60px", height: "60px", borderRadius: "10px" }}
                    />
                  </td>
                  <td className="responsive-design" style={{ border: "none" }}>
                    {item.title_english.length > 20
                      ? item.title_english.substring(0, 20) + "..."
                      : item.title_english}
                  </td>
                  <td className="responsive-design" style={{ border: "none" }}>
                    {item.description_english
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .length > 50
                      ? `${item.description_english.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 50)}...`
                      : item.description_english.replace(/<\/?[^>]+(>|$)/g, "")}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="delete-buttons-red"
                        style={{ border: "1px solid goldenrod" }}
                        onClick={() => handleEditInvester(item)}
                      >
                        <MdEdit
                          className="delete-btn-red"
                          style={{
                            color: "goldenrod",
                            marginTop: "0.5rem",
                          }}
                        />
                      </button>
                      <button
                        className="delete-buttons-red"
                        onClick={() => handleShow(item)}
                      >
                        <img
                          src={Delete}
                          alt="Delete"
                          className="delete-btn-red"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </Draggable>
          ))
        ) : (
          <tr>
            <td colSpan="8">Data Not Found</td>
          </tr>
        )}
        {provided.placeholder} 
      </tbody>
    )}
  </Droppable>
                        </DragDropContext>
                        <tfoot>
                          <tr>
                              <th>Sr No.</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th> 
                                                        <th>Action</th>                           
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="Page-button-click">
                  <div onClick={() => handlepage(currentpage - 1)}>
                    <img src={Prev} alt="" className="both-Next-Prev" />
                  </div>
                  <span style={{ fontSize: "20px" }}>{currentpage}</span>
                  <div  style={{ opacity: totalPages == currentpage ? "0.5" : "1" }}
  onClick={() =>
                    handlepage(
                      totalPages == currentpage ? currentpage : currentpage + 1
                    )
                  }>
                    <img src={Next} alt="" className="both-Next-Prev" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Academy;
