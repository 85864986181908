import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import AppLayout from "../Layout/Layout";
import Postview from "../Post/Postview";
import EmailUser from "../User/EmailUser";
import SocialUser from "../User/SocialUser";
import Comment from "../Post/Comment";
import BusinessCategory from "../BusinessCategory/BusinessCategory";
import BusinessLanguage from "../Language/BusinessLanguage";
import Support from "../Support/Support";
import PostList from "../Report/PostList";
import UserList from "../Report/UserList";
import AddLanguage from "../Language/AddLanguage";
import UserDeatil from "../UserDeatil/UserDeatil";
import ContactList from "../Contact/ContactList";
import PostlistView from "../Post/PostlistView";
import SupportList from "../SupportList/SupportList";
import Invester from "../Layout/Invester/Invester";
import InvesterView from "../Layout/Invester/InvesterView";
import InvesterEdit from "../Layout/Invester/InvesterEdit";
import Blog from "../Layout/Blog/Blog";
import AddBlog from "../Layout/Blog/Addblog";
import EditBlog from "../Layout/Blog/EditBlog";
import Academy from "../Layout/Academy/Academy";
import AddAcademy from "../Layout/Academy/AddAcademy";
import EditAcademy from "../Layout/Academy/EditAcademy";
import MessageBlog from "../Layout/MessageBlog/MessageBlog";
import EditMessageBlog from "../Layout/MessageBlog/EditMessageBlog";
import MessageAcademy from "../Layout/MessageAcademy/MessageAcademy";
import EditMessageAcadamy from "../Layout/MessageBlog/EditMessageAcadamy";
const LayoutRoutes = () => {
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}>
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
              <Route path="/Postview" element={<Postview />} />
              <Route path="/business-email-user" element={<EmailUser />} />
              <Route path="/business-social-user" element={<SocialUser />} />
              <Route path="/Comment" element={<Comment />} />
              <Route
                path="/industry-category-list"
                element={<BusinessCategory />}
              />
              <Route path="/Support" element={<Support />} />
              <Route path="/report-Post-List" element={<PostList />} />
              <Route path="/report-User-List" element={<UserList />} />
              <Route path="/AddLanguage" element={<AddLanguage />} />
              <Route path="/business-user-deatil" element={<UserDeatil />} />
              <Route path="/ContactList" element={<ContactList />} />
              <Route path="/setting-add-label" element={<BusinessLanguage />} />
              <Route path="/PostListView" element={<PostlistView />} />
              <Route path="/SupportList" element={<SupportList />} />
              <Route path="/Invester" element={<Invester />} />
              <Route path="/InvesterView" element={<InvesterView />} />
              <Route path="/InvesterEdit" element={<InvesterEdit />} />
              <Route path="/Blog" element={<Blog />} />
              <Route path="/AddBlog" element={<AddBlog />} />
              <Route path="/EditBlog" element={<EditBlog />} />
              <Route path="/Academy" element={<Academy />} />
              <Route path="/AddAcademy" element={<AddAcademy />} />
              <Route path="/EditAcademy" element={<EditAcademy />} />
              <Route path="/MessageBlog" element={<MessageBlog />} />
              <Route path="/MessageAcademy" element={<MessageAcademy />} />
              <Route path="/EditMessageBlog" element={<EditMessageBlog />} />
              <Route path="/EditMessageAcadamy" element={<EditMessageAcadamy />} />
            </Route>
          </Fragment>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;
